import React from "react";
import Layout from "components/layout";
import { withI18next } from "lib/withI18next";
import { autobind } from "react-decoration";
import { page } from "components/page";
import { navigate } from "gatsby";
import qs from "query-string";
import gql from "graphql-tag";
import client from "lib/ApolloClient";

const setLogin = gql`
  mutation setLogin($form: LoginForm) {
    result: setLogin(Input: $form) {
      success
      errorType
      sessionID
    }
  }
`;

const getIntegrationDBChooseList = gql`
  query getIntegrationDBChooseList {
    getRecommendClass{
      dbID
      ebName
      enEbName
      hyintID
      url
      maintain
      gIsQuery
      fullTextNo
      eIsQuery
    }
  }
`;

@withI18next(["common"])
@page
class DLink extends React.Component {
  constructor(props) {
    super(props);
    let webAppName = props.appStore.globalConfig["jumperrwd.common.webAppName"] || "";
    this.state = {
      auth: props.readerStore.auth,
      webAppName: webAppName,
    };
  }

  async componentDidMount() {
    await this.dLink(this.props);
  }

  @autobind
  async dLink(props) {
    let { appStore, search } = props;
    let { globalConfig } = appStore;
    let params = { ...search };
    let {
      rType,
      uid,
      pass,
      url,
      mode,
      searchField,
      searchInput,
      op,
      resourceType,
      searchTarget,
      db,
      centralized,
      decentralized,
      id,
    } = params;

    if (resourceType === "ejournal") {
      resourceType = "journal";
    }

    if (uid && pass) {
      await client.jumperrwdClient
        .mutate({
          mutation: setLogin,
          variables: {
            form: {
              user: uid,
              password: pass,
              encryptType: "des",
            },
          },
        })
        .then(async (res) => {
          if (res.data.result.success) {
            await this.timeout(1000);
            await this.setLogin();
          }
        });
    }

    if (rType === "redirectByLogin") {
      if (this.state.auth) {
        navigate(url);
      } else {
        if (this.state.webAppName === "fcu") {
          let oauthParams = {
            login: "true",
            url: url
          };
          navigate(["/oauth", qs.stringify(oauthParams)].join("?"));
        } else {
          let apiV3Params = {
            url: url
          };
          navigate(["/sendURLApiV3", qs.stringify(apiV3Params)].join("?"));
        }
      }
    } if (rType === "resourceListByLogin") {
      let listParams = {
        pageType: "list",
        resourceType,
      };
      let listURL = ["/resourceList", qs.stringify(listParams)].join("?");
      if (this.state.auth) {
        navigate(listURL);
      } else {
        if (this.state.webAppName === "fcu") {
          let oauthParams = {
            login: "true",
            url: listURL
          };
          navigate(["/oauth", qs.stringify(oauthParams)].join("?"));
        } else {
          let apiV3Params = {
            url: listURL
          };
          navigate(["/sendURLApiV3", qs.stringify(apiV3Params)].join("?"));
        }
      }
    } else if (rType === "resourceDetailByLogin") {
      let detailParams = {
        resourceType: resourceType?resourceType:"databases",
        id,
      };
      let detailURL = ["/resourceDetail", qs.stringify(detailParams)].join("?");
      if (this.state.auth) {
        navigate(detailURL);
      } else {
        let apiV3Params = {
          url: detailURL
        };
        navigate(["/sendURLApiV3", qs.stringify(apiV3Params)].join("?"));
      }
    } else if (rType === "resourceRedirectByLogin") {
      let redirectParams = {
        mode,
        searchField,
        searchInput,
        op,
        resourceType,
        searchTarget,
      };
      searchField = [].concat(searchField || []);
      searchInput = [].concat(searchInput || []);
      op = [].concat(op || []);
      let rediertURL = ["/resourceRedirect", qs.stringify(redirectParams)].join("?");
      if (this.state.auth) {
        navigate(rediertURL);
      } else {
        let apiV3Params = {
          url: rediertURL
        };
        navigate(["/sendURLApiV3", qs.stringify(apiV3Params)].join("?"));
      }
    } else if (rType === "searchRedirectEdsByLogin") {
      let redirectParams = {
        searchInput,
        searchField,
        op,
        mode,
        centralized: "Y",
        decentralized: "N",
      };
      let rediertURL = ["/searchResult", qs.stringify(redirectParams)].join("?");
      if (this.state.auth) {
        navigate(rediertURL);
      } else {
        let apiV3Params = {
          url: rediertURL
        };
        navigate(["/sendURLApiV3", qs.stringify(apiV3Params)].join("?"));
      }
    } else if (rType === "resourceSearch") {
      let redirectParams = {
        mode,
        searchField,
        searchInput,
        op,
        resourceType,
        searchTarget,
      }
      searchField = [].concat(searchField || []);
      searchInput = [].concat(searchInput || []);
      op = [].concat(op || []);
      navigate(["/resourceRedirect", qs.stringify(redirectParams)].join("?"));
    } else if (rType === "integrationSearch") {
      db = [].concat(db || []);
      if (decentralized === "Y") {
        if (db.length === 0) {
          db = await client.jumperrwdClient
            .query({
              query: getIntegrationDBChooseList
            })
            .then((res) => {
              let dbTemp = [];
              let { getRecommendClass } = res.data;
              getRecommendClass.forEach((item) => {
                dbTemp.push(item.dbID);
              })
              return dbTemp;
            });
        }
      }
      let redirectParams = {
        searchInput,
        searchField,
        op,
        db,
        mode,
        centralized,
        decentralized
      }
      if(globalConfig["jumper.common.eds.issearchboxapi"] === "1"){
        let redirectEDSParams = {
          searchKey: searchInput,
        };
        navigate(["/searchRedirectEDS", qs.stringify(redirectEDSParams)].join("?"));
      }else{
        navigate(["/searchResult", qs.stringify(redirectParams)].join("?"));
      }
    } else {
      navigate("/");
    }
  }

  @autobind
  async setLogin() {
    await this.props.readerStore.syncSessionCookie();
  }

  @autobind
  timeout(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
  }

  render() {
    let { t, isprivate, i18n } = this.props;
    return (
      <Layout t={t} i18n={i18n} isprivate={isprivate} hiddenBg={true}>
        <div
          ref={(c) => {
            this.container = c;
          }}
        />
      </Layout>
    );
  }
}

export default DLink;
